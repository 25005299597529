.link {
  cursor: pointer;
  font-size: 18px;
  position: relative;
  white-space: nowrap;
  display: block;
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
  text-transform: uppercase;
  line-height: 1.5;
}

.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: rgb(197, 197, 197);
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link::before {
  content: '';
  /* show by default */
}

.link:hover {
  color: white;
}
.links::before {
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

.links:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
  color: white;
}

.lineBreaker {
  margin: 30px auto;
  width: 65vw;
  content: '';
  width: auto;
  height: 5px;
  background: linear-gradient(
    90deg,
    #262626 0%,
    rgba(255, 165, 9, 0.85) 34%,
    rgba(255, 165, 9, 0.85) 62%,
    #262626 100%
  );
  /* rgba(255,165,9,0.85) */
  position: relative;
}

.box {
  width: 16rem;
  height: 40rem;
  margin: 20px auto;
  padding: 2px;
  /* border: none !important; */
  background: #262626 !important;
  background-color: #262626;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.partner {
  margin: 20px auto;
  padding: 2px;
  background: #262626 !important;
  background-color: #262626;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.box:hover {
  box-shadow: 0 0 12px rgba(255, 165, 9, 0.4);
}

.partner:hover {
  box-shadow: 0 0 12px rgba(255, 165, 9, 0.4);
}

.cardBody {
  background: #262626;
  border-top: none;
  /* position: relative; */
}

.teamSocialContainer {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  bottom: 0;
}
