/*Overrides must match specificity of pre-loaded styles!*/
/*The simplest way to get the selector is with devtools*/
#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
  background-color: orange;
}

.fp-slidesNav ul {
  text-align: center;
}
.fp-arrow.fp-next {
  border-color: transparent transparent transparent orange !important;
  border-width: 24.5px 0 24.5px 20px !important;
}
.fp-arrow.fp-prev {
  border-color: transparent orange transparent transparent !important;
  border-width: 24.5px 20px 24.5px 0 !important;
}
