body,
html {
  background: #262626;
  color: #fff;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

.fp-watermark {
  display: none;
}